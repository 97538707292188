var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fDate } from '../../../utils';
import axios from '../../../utils/axios';
// get Created By me
export var getTodosCreatedbyMe = createAsyncThunk('TODOS/GET-ME', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var page, filterPriority, limit, filterDescription, filterStatus, endDate, startDate, params, data, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                page = payload.page, filterPriority = payload.filterPriority, limit = payload.limit, filterDescription = payload.filterDescription, filterStatus = payload.filterStatus, endDate = payload.endDate, startDate = payload.startDate;
                params = __assign(__assign(__assign(__assign({ page: page, limit: limit }, (filterDescription ? { search: filterDescription } : {})), (filterStatus !== 'all' ? { status: filterStatus } : {})), (filterPriority !== 'all' ? { priority: filterPriority } : {})), (startDate && endDate
                    ? {
                        startDate: fDate(startDate, 'yyyy-MM-dd'),
                        endDate: fDate(endDate, 'yyyy-MM-dd'),
                    }
                    : {}));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/todos', { params: params })];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// get Assigned to me
export var getTodosFilteredTodos = createAsyncThunk('TODOS/GET-ASSIGN', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var page, limit, filterDescription, filterStatus, filterEndDate, filterStartDate, assigned, filterAdmin, filterPriority, params, data, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                page = payload.page, limit = payload.limit, filterDescription = payload.filterDescription, filterStatus = payload.filterStatus, filterEndDate = payload.filterEndDate, filterStartDate = payload.filterStartDate, assigned = payload.assigned, filterAdmin = payload.filterAdmin, filterPriority = payload.filterPriority;
                params = __assign(__assign(__assign(__assign(__assign(__assign({ page: page, limit: limit }, (filterDescription ? { search: filterDescription } : {})), (filterStatus !== 'all' ? { status: filterStatus } : {})), (filterPriority !== 'all' ? { priority: filterPriority } : {})), (filterStartDate && filterEndDate
                    ? {
                        startDate: fDate(filterStartDate, 'yyyy-MM-dd'),
                        endDate: fDate(filterEndDate, 'yyyy-MM-dd'),
                    }
                    : {})), (assigned && { assigned: assigned })), (filterAdmin && { author: filterAdmin._id }));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/todos/filter', { params: params })];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_2 = _a.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// Create Todo
export var createNewTodo = createAsyncThunk('TODOS/POST', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, body, response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = payload.body;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post('/todos', body)];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_3 = _a.sent();
                return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// DELETE ONE
export var deleteOneTodo = createAsyncThunk('TODOS/DELETE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, todoId, response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                todoId = payload.todoId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.delete("todos/".concat(todoId))];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_4 = _a.sent();
                return [2 /*return*/, Promise.reject(err_4.message ? err_4.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// UPDATE ONE
export var updateTodo = createAsyncThunk('TODOS/EDIT', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, todoId, body, response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                todoId = payload.todoId, body = payload.body;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.patch("todos/".concat(todoId), body)];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_5 = _a.sent();
                return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// UPDATE ONE
export var updateTodoStatusAndPriority = createAsyncThunk('TODOS/EDIT-STATUS', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, todoId, body, response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                todoId = payload.todoId, body = payload.body;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.patch("todos/status/".concat(todoId), body)];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_6 = _a.sent();
                return [2 /*return*/, Promise.reject(err_6.message ? err_6.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// GET ONE
export var getOneTodo = createAsyncThunk('TODO/GetONE', function (todoId) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.get("todos/".concat(todoId))];
            case 1:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 2:
                err_7 = _a.sent();
                return [2 /*return*/, Promise.reject(err_7.message ? err_7.message : data === null || data === void 0 ? void 0 : data.message)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getOfficesAndUsers = createAsyncThunk('USER_OFFICE/GET', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, page, limit, search, params, response, err_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                page = payload.page, limit = payload.limit, search = payload.search;
                params = __assign({ page: page, limit: limit }, (search ? { search: search } : {}));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('todos/users-offices', { params: params })];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_8 = _a.sent();
                return [2 /*return*/, Promise.reject(err_8.message ? err_8.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
